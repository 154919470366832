
export default {
	props: {
		link: {
			type: String,
			default: ''
		},
		buttonClass: String,
		text: String,
		icon: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		}
	}
}
