
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: {
    phone: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings",
      isNight: "settings/settings/isNight",
    })
  },
  methods: {
    ...mapMutations({
      setNight: "settings/settings/SET_NIGHT"
    }),
    ...mapActions({
      openModal: "modal/modal-main/openModal",
      sendForm: "form/form/sendForm"
    }),
    async callback() {
      let payload = {
        modal_component: "modal-callback-main"
      };
      await this.openModal(payload);
    }
  }
};
